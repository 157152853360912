:root {
  /** Colors **/
  --color-primary: #59CBE4;
  --color-secondary: #6a5cff;
  --color-success: #59e480;
  --color-font-success: #1ebf4b;
  --color-info: #59CBE4;
  --color-warning: #fbca4d;
  --color-error: #FB7171;
  --color-background: #f4f4f4;
  --color-header: #f8faff;
  --color-black: #000000;
  --color-dark-gray: #303030;
  --color-gray: #777777;
  --color-metalic: #919098;
  --color-light-gray: #e0e0e0;
  --color-whitesmoke: #f1f1f1;
  --color-off-white: #f5f5f5;
  --color-white: #ffffff;

  /** Gradients **/
  --gradient-main: linear-gradient(90deg, #00cdff 0%, #6a5cff 100%);

  /** Border Radius **/
  --border-radius-card: 16px;
  --border-radius-input: 8px;
  --border-radius-component: 8px;
  --border-radius-button: 4px;

  /** Font Sizes **/
  --font-title: 28px;
  --font-subtitle: 24px;
  --font-big: 20px;
  --font-medium: 16px;
  --font-small: 14px;
  --font-tiny: 10px;

  /** Elevations **/
  --elevation-low: 0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132);
  --elevation-medium: 0px 0.6px 1.8px rgba(0, 0, 0, 0.108), 0px 3.2px 7.2px rgba(0, 0, 0, 0.132);
  --elevation-high: 0px 1.2px 3.6px rgba(0, 0, 0, 0.108), 0px 6.4px 14.4px rgba(0, 0, 0, 0.132);
  --elevation-very-high: 0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22);
}